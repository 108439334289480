<template>
    <header>
        <div class="navi_top">
            <h1>KT 차세대매장 <br>디지털컨텐츠 운영 시스템</h1>
        </div>
        <div class="gnb">
            <ul>
                <li class="">
                    <a href="javascript:;">태블릿 VMD</a>
                    <div class="sub_wrap">
                        <ul>
                            <li><a href="javascript:void(0)" @click="redirect('/vmd')">VMD 관리</a></li>
                            <li><a href="javascript:void(0)" @click="redirect('/channel')">채널 관리</a></li>
                        </ul>
                    </div>
                </li>
                <li class="">
                    <a href="javascript:;">KT 서비스 체험존</a>
                    <div class="sub_wrap">
                        <ul>
                            <li><a href="javascript:void(0)" @click="redirect('/mainVideo')">메인 영상 관리</a></li>
                            <li>
                              <a href="javascript:void(0)" @click="redirect('/subMenu/hotDeal')">서브 메뉴 관리</a>
                              <ul class="depth3">
                                <li><a href="javascript:void(0)" @click="redirect('/subMenu/hotDeal')">우리매장핫딜</a></li>
                                <li><a href="javascript:void(0)" @click="redirect('/subMenu/halfPrice')">반값초이스</a></li>
                                <li><a href="javascript:void(0)" @click="redirect('/subMenu/cards')">제휴카드</a></li>
                                <li><a href="javascript:void(0)" @click="redirect('/subMenu/products')">제휴상품</a></li>
                              </ul>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div class="navi_foot">
            <div class="login_info">
                <span class="name"><a href="javascript:void(0)" @click="redirect('/account/modify')"><strong>관리자</strong> 님</a></span>
                <button @click="logout" type="button" class="btn_logout">로그아웃</button>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "adminHeader",
    methods:{
        logout(){
            this.$store
                    .dispatch("LOGOUT")
                    .then((msg) => {
                        this.$router.push('/login');
                    })
                    .catch((err) => {
                        console.log(err);
                    })
        }
    }
}
</script>

<style scoped>

</style>