<template>
<!--    <footer id="footer"></footer>-->
</template>

<script>
export default {
    name: "adminFooter"
}
</script>

<style scoped>

</style>